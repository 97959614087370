import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from './ApiUtils';
import moment from 'moment';
import * as FakeData from '../FakeData';
import * as UploadErrorFakeData from '../UploadErrorFakeData';

const mock = false;
const mockError = false;

const UserOutlet = getOutlet('user');
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Provider {
  getHeaders() {
    throw new Error('not impl');
  }

  transfromDataFromMainProduct() {
    throw new Error('not impl');
  }

  async upload(json) {
    throw new Error('not impl');
  }
}

class Rakuten extends Provider {
  getHeaders() {
    return [
      'internal_id',
      'sku',
      '名稱',
      '簡介',
      '品類',
      '品牌',
      '售價',
      '重量',
      '尺寸長',
      '尺寸寬',
      '尺寸高',
      '庫存',
      'image',
    ];
  }

  errorParser(resp) {
    let errorData = {
      title: '錯誤',
      detail: '上傳失敗',
      raw: resp,
    };
    try {
      let baseErrorData = resp.operations[0].errors;
      let errorDataDetail =
        baseErrorData.specific[baseErrorData.failedResources[0]][0];
      errorData.title = `${errorDataDetail.errorCode}-${errorDataDetail.shortMessage}`;
      errorData.detail = `${errorDataDetail.longMessage}`;
    } catch (err) {
      //
    }
    return errorData;
  }

  transfromDataFromMainProduct(product) {
    return {
      // 'internal_id',
      sku: product.sku_id,
      名稱: product.name,
      簡介: product.info_html,
      品類: product.sku_category_rakuten,
      品牌: product.brand,
      售價: product.sale_price,
      市價: product.market_price,
      重量: product.sku_box_weight || 0,
      尺寸長: product.sku_box_l || 0,
      尺寸寬: product.sku_box_w || 0,
      尺寸高: product.sku_box_h || 0,
      庫存: 1,
      安全庫存: product.safe_stock_count || 1,
      image: product.sku_image,
    };
  }

  async upload(json) {
    const data = {
      variants: [
        {
          sku: json.sku,
          shippingPackage: {
            weight: {
              value: parseInt(json['重量'], 10),
            },
            dimensions: {
              width: parseInt(json['寬'], 10),
              depth: parseInt(json['長'], 10),
              height: parseInt(json['高'], 10),
            },
          },
          inventory: {
            quantity: json['庫存'],
            alertThreshold: json['安全庫存'],
          },
        },
      ],
      productListedShops: [
        {
          shopKey: {
            marketplaceIdentifier: 'tw',
            shopUrl: 'ya-kuang',
          },
          title: {
            zh_TW: json['名稱'],
          },
          description: {
            zh_TW: json['簡介'],
          },
          brand: json['品牌'],
          variantInfos: {
            [json.sku]: {
              pricing: {
                price: parseInt(json['售價'], 10),
                listPrice: parseInt(json['市價'], 10),
              },
              purchaseQuantityLimit: {
                max: 10,
              },
            },
          },
          marketplaceRakutenCategoryId: json['品類'], // '10910'
          images: [
            {
              url: json.image,
              alt: 'default',
            },
          ],
        },
      ],
      baseSku: json.sku,
      shopCategories: [],
    };

    if (mockError) {
      return {
        result: false,
        data: null,
        error: this.errorParser(UploadErrorFakeData.rakuten),
      };
    }

    if (mock) {
      return {
        result: true,
        data: {id: json.sku, internal_id: json['internal_id']},
      };
    }

    const addResp = await req(
      `${Config.apiHost}/rakuten/product/add?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data: {products: [data]},
      },
    );

    const {operationId} = addResp;
    let statusResp;

    if (operationId) {
      await delay(5000);
      statusResp = await req(
        `${Config.apiHost}/rakuten/get/operation?token=${
          UserOutlet.getValue().token
        }&operation_id=${operationId}`,
        {
          method: 'POST',
        },
      );

      if (statusResp.operations[0].failureCount === 0) {
        return {
          result: true,
          data: {
            // TODO: @rakuten-id-mapping
            id: json.sku, // ex: addResp.rakuten_id
            internal_id: json['internal_id'],
          },
        };
      }
    }

    return {
      result: false,
      data: null,
      error: this.errorParser(statusResp || addResp),
    };
  }
}

class Shopee extends Provider {
  getHeaders() {
    return [
      'internal_id',
      'sku',
      '名稱',
      '簡介',
      '品類',
      '品牌',
      '售價',
      '運費',
      '尺寸長',
      '尺寸寬',
      '尺寸高',
      '庫存',
      'image1',
      'image2',
    ];
  }

  errorParser(resp) {
    let errorData = {
      title: '錯誤',
      detail: '上傳失敗',
      raw: resp,
    };
    try {
      errorData.title = resp.error;
      errorData.detail = resp.message;
    } catch (err) {
      //
    }
    return errorData;
  }

  transfromDataFromMainProduct(product) {
    return {
      // 'internal_id',
      sku: product.sku_id,
      名稱: product.name,
      簡介: product.info_shopee,
      品類: product.sku_category_shopee,
      品牌: product.brand,
      售價: product.sale_price,
      運費: product.shipping_cost,
      尺寸長: product.sku_box_l || 0,
      尺寸寬: product.sku_box_w || 0,
      尺寸高: product.sku_box_h || 0,
      重量: product.sku_box_weight || 10, // new field from excel
      庫存: 1,
      image1: product.sku_image_1_shopee,
      image2: product.sku_image_2_shopee,
      image3: product.sku_image_3_shopee,
    };
  }

  async upload(json) {
    const data = {
      original_price: parseInt(json['售價'], 10),
      description: json['簡介'],
      weight: json['重量'],
      item_name: json['名稱'],
      item_status: 'NORMAL',
      dimension: {
        package_length: parseInt(json['尺寸長'], 10),
        package_width: parseInt(json['尺寸寬'], 10),
        package_height: parseInt(json['尺寸高'], 10),
      },
      logistic_info: [
        {
          size_id: 0,
          shipping_fee: parseFloat(json['運費']),
          enabled: true,
          is_free: false,
          logistic_id: 39304,
        },
      ],
      normal_stock: parseInt(json['庫存'], 10),
      item_sku: json.sku,
      condition: 'NEW',
      brand: {
        brand_id: 0,
        original_brand_name: json['品牌'],
      },
      item_dangerous: 0,
      category_id: json['品類'], // 100159 will work
      image: {
        image_id_list: [json.image1, json.image2, json.image3],
      },
    };

    if (mockError) {
      return {
        result: false,
        data: null,
        error: this.errorParser(UploadErrorFakeData.shopee),
      };
    }

    if (mock) {
      return {
        result: true,
        data: {
          id: FakeData.shopee.response.item_id,
          internal_id: json['internal_id'],
        },
      };
    }

    const resp = await req(
      `${Config.apiHost}/shopee/add/item?token=${UserOutlet.getValue().token}`,
      {
        method: 'POST',
        data,
      },
    );
    if (!resp.error) {
      return {
        result: true,
        data: {id: resp.response.item_id, internal_id: json['internal_id']},
      };
    }
    return {result: false, data: null, error: this.errorParser(resp)};
  }
}

class Yahoomall extends Provider {
  getHeaders() {
    return [
      'internal_id',
      'sku',
      '名稱',
      '商店類別碼',
      '品類',
      '建議售價',
      '售價',
      '採購成本',
      '運費識別碼',
      '簡介',
      '庫存',
      '安全庫存',
      '商品購買上限',
    ];
  }

  errorParser(resp) {
    let errorData = {
      title: '錯誤',
      detail: '上傳失敗',
      raw: resp,
    };
    try {
      let baseErrorArr = resp.Response.ErrorList.Error;
      errorData.title = baseErrorArr
        .reduce(
          (acc, curr) => [...acc, `[${curr['@Code']}]${curr.Parameter}`],
          [],
        )
        .join('::');
      errorData.detail = baseErrorArr
        .reduce(
          (acc, curr) => [...acc, `[${curr['@Code']}]${curr.Message}`],
          [],
        )
        .join('::');
    } catch (err) {
      //
    }
    return errorData;
  }

  transfromDataFromMainProduct(product) {
    return {
      // 'internal_id',
      sku: product.sku_id,
      名稱: product.name,
      商店類別碼: product.sku_category_yahoomall,
      // '品類': ignore optional,
      建議售價: product.market_price,
      售價: product.sale_price,
      採購成本: product.cost,
      運費識別碼: product.shipping_id_yahoomall,
      簡介: product.info,
      庫存: 1,
      安全庫存: product.safe_stock_count,
      商品購買上限: 5,
    };
  }

  async upload(json) {
    const data = {
      ProductName: json['名稱'],
      SaleType: 'Normal',
      MallCategoryId: parseInt(json['商店類別碼'], 10),
      // StoreCategoryId: parseInt(json['品類'], 10),
      MarketPrice: parseInt(json['建議售價'], 10),
      SalePrice: parseInt(json['售價'], 10),
      CostPrice: parseInt(json['採購成本'], 10),
      MaxBuyNum: parseInt(json['商品購買上限'], 10),
      ShortDescription: json['簡介'],
      PayTypeId: 1,
      ShippingId: parseInt(json['運費識別碼'], 10),
      SpecTypeDimension: '0',
      CustomizedMainProductId: json['sku'],
      Stock: parseInt(json['庫存'], 10),
      SaftyStock: parseInt(json['安全庫存'], 10),
    };

    if (mockError) {
      return {
        result: false,
        data: null,
        error: this.errorParser(UploadErrorFakeData.yahoomall),
      };
    }

    if (mock) {
      return {
        result: true,
        data: {
          id: FakeData.yahoomall.Response.ProductId,
          internal_id: json['internal_id'],
        },
      };
    }

    const resp1 = await req(
      `${Config.apiHost}/yahoo-mall/product/verify?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data,
      },
    );

    if (resp1.Response['@Status'] !== 'ok') {
      return {result: false, data: null, error: this.errorParser(resp1)};
    }

    const resp2 = await req(
      `${Config.apiHost}/yahoo-mall/product/submit?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data,
      },
    );

    const providerProductId = resp2.Response['ProductId'];
    const makeProductOnlineResp = await req(
      `${Config.apiHost}/yahoo-mall/product/online?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data: [providerProductId],
      },
    );

    if (resp2.Response['@Status'] === 'ok') {
      return {
        result: true,
        data: {
          id: providerProductId,
          internal_id: json['internal_id'],
        },
      };
    }
    return {result: false, data: null, error: this.errorParser(resp2)};
  }

  async uploadProductImg(yahooProductId, internalProduct) {
    let url = internalProduct.sku_image;
    const imageResponse = await fetch(url);
    const blob = await imageResponse.blob();
    const file = new File([blob], `image.${blob.type.split('/').pop()}`, {
      type: blob.type,
    });

    let formData = new FormData();
    formData.append('ImageFile1', file);

    const uploadImgResp = await fetch(
      `${Config.apiHost}/yahoo-mall/upload/image?token=${
        UserOutlet.getValue().token
      }&ProductId=${yahooProductId}`,
      {
        method: 'POST',
        headers: {},
        body: formData,
      },
    );

    return await uploadImgResp.json();
  }
}

class Momomall extends Provider {
  getHeaders() {
    return [
      'internal_id',
      'sku',
      '名稱',
      '品類',
      '商店類別碼',
      '品牌',
      '建議售價',
      '售價',
      '採購成本',
      '簡介',
      '庫存',
      '安全庫存',
    ];
  }

  errorParser(resp) {
    let errorData = {
      title: '錯誤',
      detail: '上傳失敗',
      raw: resp,
    };
    try {
      errorData.title = resp.errorMessageList[0];
      errorData.detail = `status: ${resp.status}`;
    } catch (err) {
      //
    }
    return errorData;
  }

  transfromDataFromMainProduct(product) {
    return {
      // 'internal_id',
      sku: product.sku_id,
      名稱: product.name,
      品類: product.sku_category_momomall,
      商店類別碼: product.store_category_momomall,
      品牌: product.brand,
      建議售價: product.market_price,
      售價: product.sale_price,
      採購成本: product.cost,
      簡介: product.info_html,
      庫存: 1,
      安全庫存: product.safe_stock_count,
    };
  }

  async upload(json) {
    const startDateDataArr = moment().format('YYYY-MM-DD::HH::mm').split('::');
    const data = {
      goodsNameBrand: json['品牌'],
      goodsName: json['名稱'],
      goodsNameSeries: json['名稱'], // for momomall search keywords
      categoryCode: json['品類'],
      categoryByEntpCode: [parseInt(json['商店類別碼'])],
      taxYn: '1',
      applyDate: [startDateDataArr[0]],
      applyDateHH: [startDateDataArr[1]],
      applyDateMM: [startDateDataArr[2]],
      buyPrice: [parseInt(json['採購成本'], 10)],
      salePrice: [parseInt(json['售價'], 10)],
      salePriceCheck: [parseInt(json['售價'], 10)],
      custPrice: [parseInt(json['建議售價'], 10)], // FIXME: 建議售價 momomall ui 沒有顯示？
      colSeq1: '001',
      colType1: '1', // 規格名稱
      goodsdtInfo1: ['單一規格'], // 規格選項
      colSeq2: '',
      colType2: '',
      goodsdtInfo2: [''],
      canSaleQty: [parseInt(json['庫存'], 10)],
      safeQty: [parseInt(json['安全庫存'], 10)],
      entpGoodsNo: [json['sku']],
      advanceYn: '0',
      prepareDate: [],
      cashFlowNo: ['001', '002'], // FIXME: unknown field
      logisticsNo: ['010'],
      goodsTitle: '',
      goodsDescribe: json['簡介'],
      htmlEditor: '<html>\n <head></head>\n <body>\n </body>\n</html>',
      goodsNote: '詳如商品特色',
      saleGb: Config.stage === 'prod' ? '99' : '00',
      appendAdd: [''],
      appendPresent: [''],
      picFileName: [''],
      delyType: '01',
      goodsDtPicFileName1: [''],
      goodsDtPicFileName2: [''],
      htmlEditorPicFileName: [''],
    };

    if (mockError) {
      return {
        result: false,
        data: null,
        error: this.errorParser(UploadErrorFakeData.momomall),
      };
    }

    if (mock) {
      return {
        result: true,
        data: {
          id: FakeData.momomall.outputObject.data.goodsCode,
          internal_id: json['internal_id'],
        },
      };
    }

    const resp1 = await req(
      `${Config.apiHost}/momomall/product/verify?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data,
      },
    );

    if (resp1.errorMessageList.length !== 0) {
      return {result: false, data: null, error: this.errorParser(resp1)};
    }

    const resp2 = await req(
      `${Config.apiHost}/momomall/product/submit?token=${
        UserOutlet.getValue().token
      }`,
      {
        method: 'POST',
        data,
      },
    );

    if (resp2.errorMessageList.length === 0) {
      return {
        result: true,
        data: {
          id: resp2.outputObject?.data?.goodsCode || null,
          internal_id: json['internal_id'],
        },
      };
    }
    return {result: false, data: null, error: this.errorParser(resp2)};
  }
}

const Providers = {
  shopee: new Shopee(),
  rakuten: new Rakuten(),
  yahoomall: new Yahoomall(),
  momomall: new Momomall(),
};

const ProviderNames = Object.keys(Providers);

async function uploadAllProviderProducts(uploadContent) {
  const resultsMap = {};
  for (const name of ProviderNames) {
    const products = uploadContent[name] || [];
    const handler = Providers[name];
    const results = [];
    for (const product of products) {
      let result = false;
      try {
        result = await handler.upload(product);
      } catch (ex) {
        console.warn(ex);
      }
      results.push(result);
    }
    resultsMap[name] = results;
  }
  return resultsMap;
}

function transfromDataFromMainProduct(providerKey, mainProduct) {
  try {
    return Providers[providerKey].transfromDataFromMainProduct(mainProduct);
  } catch (err) {
    return null;
  }
}

export {
  Providers,
  ProviderNames,
  uploadAllProviderProducts,
  transfromDataFromMainProduct,
};
