import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Form, Button, message, notification, Modal, Checkbox} from 'antd';
import * as JStorageActions from '../../Actions/JStorage';
import {FormField} from './FormField';
import * as AppActions from '../../AppActions';
import MainProductToInternalProductModal from '../../Components/MainProductToInternalProductModal';
import {
  Providers,
  ProviderNames,
  uploadAllProviderProducts,
  transfromDataFromMainProduct,
} from '../../Utils/Providers';
import mainProductToInternalProduct from '../../Utils/MainProductToInternalProduct';
import getMainProductCost from '../../Utils/MainProductCostCalc';
import {FIELDS} from './FIELDS';
import {PROVIDER} from '../../Constants';
import * as Tools from '../Upload/Tools'; // FIXME: should refactor, move Tools to uper folder

const ACTIVE_MODAL_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
};

function MainProductDetail(props) {
  const {product, onConfirm} = props;
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [snapshotFormValues, setSnapshotFormValues] = React.useState({});
  const [internalProduct, setInternalProduct] = React.useState(null);
  const [mainProduct, setMainProduct] = React.useState(product);
  const [activeModal, setActiveModal] = React.useState(null);

  React.useEffect(() => {
    const _fetchInternalProduct = async () => {
      try {
        AppActions.setLoading(true);
        let _internalProduct = await JStorageActions.fetchOneDocument(
          'product',
          {
            id: product.sku_id,
          },
        );

        setInternalProduct(_internalProduct);
      } catch (err) {
        console.log(err);
        if (err.status === 404) {
          // no product yet...
        } else {
          message.error('無法取得內部產品資料');
        }
        setInternalProduct(null);
      } finally {
        AppActions.setLoading(false);
      }
    };

    if (product.id) {
      _fetchInternalProduct();
    }
  }, [product.id]);

  const defaultFormValues = React.useMemo(() => {
    let _initial = {};
    for (let f of FIELDS) {
      _initial[f.key] =
        f.defaultValue !== undefined ? f.defaultValue : undefined;

      if (f.units && f.units.defaultValue !== undefined) {
        _initial[f.units.key] = f.units.defaultValue;
      }

      if (f.others) {
        if (f.others.defaultValue !== undefined) {
          _initial[f.others.input_key] = f.others.defaultValue;
        }

        if (f.others.units && f.others.units.defaultValue !== undefined) {
          _initial[f.others.units.key] = f.others.units.defaultValue;
        }
      }
    }
    return _initial;
  }, []);

  React.useEffect(() => {
    form.setFieldsValue({
      ...defaultFormValues,
      ...(product.id ? product : {}),
    });
    // FIXME: uncertain, do we really need this to trigger re-render for FileUpload or other FormFields comp?
    setSnapshotFormValues({
      ...defaultFormValues,
      ...(product.id ? product : {}),
    });
  }, [form, defaultFormValues, product]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      let _updateValues = {...values};
      for (let key in _updateValues) {
        // do some translation if needed
      }

      let isCalcTypeChanged =
        _updateValues['cost_calc_type'] !== mainProduct['cost_calc_type'];
      let isBuyCostChanged =
        _updateValues['buy_cost'] !== mainProduct['buy_cost'];
      if (isCalcTypeChanged || isBuyCostChanged) {
        let _prevHistory = mainProduct['cost_calc_type_history'] || [];

        let timestamp = new Date().getTime();
        if (isCalcTypeChanged) {
          _prevHistory.push({
            updated: timestamp,
            from: mainProduct['cost_calc_type'] || null,
            to: _updateValues['cost_calc_type'],
            field: 'cost_calc_type',
          });
        }

        if (isBuyCostChanged) {
          _prevHistory.push({
            updated: new Date().getTime(),
            from: mainProduct['buy_cost'] || null,
            to: _updateValues['buy_cost'],
            field: 'buy_cost',
          });
        }

        console.log(_prevHistory);
        // only got latest 10 records
        _updateValues['cost_calc_type_history'] = _prevHistory.slice(-10);
      }

      if (product.id) {
        let resp = await JStorageActions.updateDocument(
          'main_product',
          {id: product.id},
          {
            ..._updateValues,
          },
        );
        form.setFieldsValue(resp);
        setSnapshotFormValues(resp);
        setMainProduct(resp);
      } else {
        let resp = await JStorageActions.createDocument('main_product', {
          ..._updateValues,
        });
        form.setFieldsValue(defaultFormValues);
        setMainProduct(resp);
      }

      onConfirm(values);
      message.success('商品已新增/更新完成');
    } catch (err) {
      message.error('無法新增商品，SKU 編號（sku_id）不可重複');
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warn('請確認所有欄位皆已正確填寫');
  };

  const onFieldsChange = (changedFields, allFields) => {
    let _formValues = allFields.reduce((acc, curr, idx) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});

    if (
      changedFields.find(
        (f) =>
          ['buy_cost', 'buy_cost_unit', 'cost_calc_type'].indexOf(f.name[0]) !==
          -1,
      )
    ) {
      _formValues['cost'] = getMainProductCost({
        buyCost: _formValues['buy_cost'],
        buyCostUnit: _formValues['buy_cost_unit'],
        costCalcType: _formValues['cost_calc_type'],
      });
      form.setFieldsValue(_formValues);
    }
    setSnapshotFormValues(_formValues);
  };

  const setFormValues = (values) => {
    // FIXME: uncertain, do we really need this to trigger re-render for FileUpload or other FormFields comp?
    form.setFieldsValue({
      ...values,
    });
    setSnapshotFormValues((_prevValues) => ({
      ..._prevValues,
      ...values,
    }));
  };

  const onSyncInternalProduct = async (extraProviderData) => {
    try {
      AppActions.setLoading(true);
      let resp = await JStorageActions.updateDocument(
        'product',
        {id: mainProduct.sku_id},
        {
          ...mainProduct,
          ...extraProviderData,
          ...mainProductToInternalProduct(mainProduct),
        },
      );
      setInternalProduct(resp);
    } catch (err) {
      message.error('無法同步內部產品！');
    } finally {
      AppActions.setLoading(false);
    }
  };

  const onMainToInternalProductBtnClick = async (type) => {
    // check if local main_product is align with remote main_product
    let mainProductDataUpdatedToRemote = true;
    for (let k in snapshotFormValues) {
      if (k === '_id') {
        continue;
      }
      if (mainProduct[k] !== snapshotFormValues[k]) {
        console.log('此欄位已更新：', k);
        mainProductDataUpdatedToRemote = false;
        break;
      }
    }

    if (!mainProductDataUpdatedToRemote) {
      message.warn('請先儲存當前資料後再進行主檔與內部商品操作');
      return;
    }

    setActiveModal(type);
  };

  const onModalConfirm = async (values) => {
    // // check which providers should be create
    // // if exists, create and then add back to internal product data
    // // reference CreateInternalProductModal comp
    let updateProviderProductData = {};

    if (activeModal === ACTIVE_MODAL_TYPE.CREATE) {
      message.info('創建內部商品中...');
      await onCreateInternalProduct(updateProviderProductData);
    }

    try {
      const _internalProduct = await JStorageActions.fetchOneDocument(
        'product',
        {
          id: product.sku_id,
        },
      );

      let uploadContent = {};

      values = values.filter(
        (v) => _internalProduct && !_internalProduct[`${v}_id`],
      );
      for (let providerKey of values) {
        let data = transfromDataFromMainProduct(providerKey, mainProduct);
        if (data) {
          uploadContent[providerKey] = [data];
        }
      }

      let resultMap = await uploadAllProviderProducts(uploadContent);
      /*
      {
          "shopee": [],
          "rakuten": [],
          "yahoomall": [
              {
                  "result": true,
                  "data": {
                      "id": "p0732234157292"
                  }
              }
          ],
          "momomall": []
      }
      */

      // manuanl upload image to yahoomall, since yahoomall need to have product before upload image
      if (resultMap[PROVIDER.yahoomall].length !== 0) {
        for (let yp of resultMap[PROVIDER.yahoomall]) {
          // actually we know only have one product in list
          if (yp.result === true) {
            try {
              await Providers.yahoomall.uploadProductImg(
                yp.data.id,
                _internalProduct,
              );
            } catch (err) {
              console.log(err);
              message.error('上傳 yahooo 商品圖片失敗');
            }
          }
        }
      }

      let errors = [];
      for (let key of Object.keys(resultMap)) {
        for (let record of resultMap[key]) {
          if (record.result) {
            updateProviderProductData[`${key}_id`] = record.data.id;
          } else {
            errors.push({
              message: record.error.title,
              description: record.error.detail,
              provider: key,
            });
          }
        }
      }

      if (errors.length > 0) {
        for (let error of errors) {
          notification.error({
            message: `[${error.provider}]${error.message}`,
            description: error.description,
            duration: 0,
          });
        }
      }
    } catch (err) {
      message.error('上傳失敗');
    }

    onSyncInternalProduct(updateProviderProductData);
    setActiveModal(null);
  };

  const onCreateInternalProduct = async (extraProviderData) => {
    try {
      AppActions.setLoading(true);
      let resp = await JStorageActions.createDocument('product', {
        ...mainProduct,
        ...extraProviderData,
        ...mainProductToInternalProduct(mainProduct),
      });
      setInternalProduct(resp);
    } catch (err) {
      message.error('無法新增內部產品！');
    } finally {
      AppActions.setLoading(false);
    }
  };

  return (
    <Wrapper>
      <div className="form">
        <Form
          form={form}
          name="create-internal-product"
          initialValues={{
            ...defaultFormValues,
          }}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete="off">
          {FIELDS.map((f, idx) => (
            <FormField
              key={idx}
              field={f}
              setFormValues={setFormValues}
              form={form}
              formValues={snapshotFormValues}
            />
          ))}

          {Array.isArray(mainProduct['cost_calc_type_history']) && (
            <div className="cost-calc-type-history-content">
              <div className="title">扣法及最新採購成本欄位更新歷史</div>
              {mainProduct['cost_calc_type_history'].length === 0 && (
                <div className="item">（無資料）</div>
              )}
              {mainProduct['cost_calc_type_history']
                .slice()
                .sort((a, b) => b.updated - a.updated)
                .map((item, idx) => (
                  <div className="item">
                    <div className="date">
                      {' '}
                      {moment(item.updated).format('YYYY-MM-DD HH:mm')}
                    </div>
                    <div>
                      {item.field === 'buy_cost' ? '最新採購成本' : '扣法'}{' '}
                      欄位更動為 {item.to}（原：{item.from || '無資料'}）
                    </div>
                  </div>
                ))}
            </div>
          )}

          <Form.Item wrapperCol={{offset: 8, span: 16}}>
            <Button loading={loading} type="primary" htmlType="submit">
              儲存
            </Button>
            {internalProduct ? (
              <Button
                htmlType="button"
                style={{marginLeft: 10}}
                onClick={() =>
                  onMainToInternalProductBtnClick(ACTIVE_MODAL_TYPE.UPDATE)
                }>
                同步內部商品
              </Button>
            ) : (
              <Button
                htmlType="button"
                style={{marginLeft: 10}}
                onClick={() =>
                  onMainToInternalProductBtnClick(ACTIVE_MODAL_TYPE.CREATE)
                }>
                轉為內部商品
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
      <Tools.UploadShopeeImageButton />
      <MainProductToInternalProductModal
        title={
          activeModal === ACTIVE_MODAL_TYPE.CREATE
            ? '轉為內部商品'
            : '同步內部產品'
        }
        type={activeModal}
        visible={!!activeModal}
        onConfirm={onModalConfirm}
        onClose={() => setActiveModal(null)}
        internalProduct={internalProduct}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .cost-calc-type-history-content {
    & .title {
      margin-bottom: 10px;
    }
    & .item {
      padding-left: 20px;
      margin-bottom: 5px;
    }
    margin-bottom: 20px;
  }
`;

export default MainProductDetail;
