import getMainProductCost from '../../Utils/MainProductCostCalc';

function transpiler(excelData, fields) {
  let convertedData = excelData.map((item) => {
    let _result = {};
    for (let k of Object.keys(item)) {
      let field = fields.find((f) => f.label === k);
      if (field) {
        _result[field.key] = item[k];
        if (field.options) {
          let foundOpt = field.options.find((op) => op.label === item[k]);
          if (foundOpt) {
            // overwrite value from option label to option value
            _result[field.key] = foundOpt.value;
          }
        }
      }
    }
    return _result;
  });

  return convertedData;
}

function transformer(results, fields) {
  let transformedResults = [];
  for (let r of results) {
    let transformedData = {...r};
    for (let f of fields) {
      if (!transformedData[f.key] && f.defaultValue !== undefined) {
        // console.log('fill in default value:', f.key, f.defaultValue)
        transformedData[f.key] = f.defaultValue;
      }

      // auto calc and fill in `cost` field
      if (f.key === 'cost') {
        let _cost = getMainProductCost({
          buyCost: r['buy_cost'],
          buyCostUnit: r['buy_cost_unit'],
          costCalcType: r['cost_calc_type'],
        });
        transformedData[f.key] = _cost;
      }
    }
    transformedResults.push(transformedData);
  }

  return transformedResults;
}

const ERROR_TYPE = {
  ID_UNIQUE: 'ID_UNIQUE',
  REQUIRED: 'REQUIRED',
  INVALID_VALUE: 'INVALID_VALUE',
  INVALID_RANGE: 'INVALID_RANGE',
};

const ERROR_DISPLAY = {
  ID_UNIQUE: '此欄位不可重複',
  REQUIRED: '此欄位為必填',
  INVALID_VALUE: '數值錯誤',
  INVALID_RANGE: '數值不符長度限制',
};

function validateData(results, fields) {
  let errorData = [];
  let skuIds = {};

  for (let r of results) {
    let errorItem = {};
    for (let f of fields) {
      // validate sku_id
      if (f.key === 'sku_id') {
        if (skuIds[r[f.key]]) {
          errorItem[f.key] = {
            ...f,
            value: r[f.key],
            errorType: ERROR_TYPE.ID_UNIQUE,
          };
        } else {
          skuIds[r[f.key]] = r[f.key];
        }
        continue;
      }
      // validate required
      if (f.required) {
        if (!r[f.key] && r[f.key] !== false) {
          errorItem[f.key] = {
            ...f,
            value: r[f.key],
            errorType: ERROR_TYPE.REQUIRED,
          };
          continue;
        }
      }

      // validate options
      if (f.options && r[f.key] !== undefined) {
        let _foundOption = f.options.find((op) => op.value === r[f.key]);
        if (!_foundOption) {
          errorItem[f.key] = {
            ...f,
            value: r[f.key],
            errorType: ERROR_TYPE.INVALID_VALUE,
          };
          continue;
        }
      }

      // validate range
      if (f.hasOwnProperty('min') || f.hasOwnProperty('max')) {
        if (f.hasOwnProperty('min')) {
          if (r[f.key].length < f['min']) {
            errorItem[f.key] = {
              ...f,
              value: r[f.key],
              errorType: ERROR_TYPE.INVALID_RANGE,
            };
            continue;
          }
          if (r[f.key].length > f['max']) {
            errorItem[f.key] = {
              ...f,
              value: r[f.key],
              errorType: ERROR_TYPE.INVALID_RANGE,
            };
            continue;
          }
        }
      }
    }

    errorData.push(errorItem);
  }

  return errorData;
}

export {transpiler, transformer, validateData, ERROR_TYPE, ERROR_DISPLAY};
