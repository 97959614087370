import React from 'react';
import {Modal, Form, Input, Checkbox, Button, message} from 'antd';
import * as JStorageActions from '../Actions/JStorage';
import styled from 'styled-components';
import {
  PROVIDER,
  PROVIDER_DISPLAY,
  PROVIDERS_BY_TYPE,
  PROVIDER_ID_TYPES,
} from '../Constants';

const mallProviderList = PROVIDERS_BY_TYPE.mall
  .filter((k) => k !== PROVIDER.momomall) // disable momomall
  .map((k) => ({
    label: PROVIDER_DISPLAY[k],
    value: PROVIDER[k],
  }));

function MainProductToInternalProductModal(props) {
  const {
    title = '',
    visible,
    internalProduct = null,
    onConfirm,
    onClose = () => 0,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [providersListToUpload, setProvidersListToUpload] = React.useState([]);

  const _onConfirm = async () => {
    setLoading(true);
    await onConfirm(providersListToUpload);
    setLoading(false);
  };
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={null}
      onCancel={onClose}
      okText="確認"
      cancelText="取消"
      footer={null}>
      <Checkbox.Group
        options={mallProviderList.map((p) => ({
          ...p,
          disabled: internalProduct && !!internalProduct[`${p.value}_id`],
        }))}
        value={providersListToUpload}
        onChange={(values) => {
          setProvidersListToUpload(values);
        }}
      />
      <div>
        <Button
          loading={loading}
          type="primary"
          onClick={_onConfirm}
          style={{marginTop: 20}}>
          送出
        </Button>
      </div>
    </Modal>
  );
}

export default MainProductToInternalProductModal;
