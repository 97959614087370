/*
最新採購成本 10000 (BB2500) => 合約成本 = 10000 - 2500 = 7500
最新採購成本 10000 (AA10%) => 合約成本 = 10000 x.9 = 9000
最新採購成本 10000 (BB2500AA10%) => (10000-2500)-10%= 7500*.9=6750
最新採購成本 10000 (AA10%BB2500) => 10000-10%-2500=9000-2500=6500
*/

function _toValidNumber(v) {
  let _v = Number(v);
  return isNaN(_v) ? 0 : _v;
}

function getMainProductCost({buyCost, buyCostUnit, costCalcType}) {
  let reg = /^(AA|BB)([0-9]+)((AA|BB)([0-9]+))?$/;
  let cost = 0;
  let costScale = buyCostUnit === 'tax_excluded' ? 1.05 : 1;

  if (!buyCost) {
    return 0;
  }

  if (!costCalcType) {
    return Number(buyCost);
  }

  if (typeof costCalcType !== 'string' || !reg.test(costCalcType)) {
    return 0;
  }

  let [, type1, value1, , type2, value2] = costCalcType.match(reg);

  if (type1 === type2) {
    // we don't have this type of cost logic: AA100AA2300, type string must be AA__BB__ or BB__AA__
    return 0;
  }

  value1 = Number(value1);
  value2 = value2 ? Number(value2) : null;
  cost = Number(buyCost);

  let _pipe = [{type: type1, value: value1}];
  if (type2) {
    _pipe.push({type: type2, value: value2});
  }

  cost = _pipe.reduce((acc, curr, idx) => {
    let {type, value} = curr;
    if (type === 'AA') {
      cost = (cost * (100 - value)) / 100;
    } else if (type === 'BB') {
      cost = cost - value;
    }
    return cost;
  }, cost);

  return cost * costScale;
}

export default getMainProductCost;
