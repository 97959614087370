export const FIELD_TYPE = {
  RADIO: 'radio',
  // BOOLEAN: 'boolean',
  SELECT: 'select',
  DEFAULT: 'default',
  INPUT_WITH_UNIT: 'input_with_unit',
  GROUP_INPTUTS: 'group_inputs',
  RADIO_WITH_INPUT: 'radio_with_input',
  TEXTAREA: 'textarea',
  UPLOAD: 'upload',
};
