import React from 'react';
import styled from 'styled-components';
import {Table, Button, Select, Input, message} from 'antd';
import moment from 'moment';
import {PageTitle} from '../../Components/Widgets';
import * as JStorageActions from '../../Actions/JStorage';
import useAntdTable from '../../Components/AntdTable/useAntdTable';
import MainProductDetail from './MainProductDetail';
import {useOutlet} from 'reconnect.js';
import EditableCell from '../../Components/EditableCell';
import getMainProductCost from '../../Utils/MainProductCostCalc';
import BulkUploadMainProductModal from './BulkUploadMainProductModal';
import * as Tools from '../Upload/Tools'; // FIXME: should refactor, move Tools to uper folder

const PAGE_SIZE = 10;
function MainProductList(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [rangeFilters, setRangeFilters] = React.useState([]);
  const [
    activeBulkUploadMainModal,
    setActiveBulkUploadMainModal,
  ] = React.useState(null);
  const [dirtyMap, setDirtyMap] = React.useState({});
  const fetchData = React.useCallback(async (configs) => {
    let result = await JStorageActions.fetchDocuments(
      'main_product',
      configs.query,
      configs.sorting,
      configs.paging,
    );
    return result;
  }, []);
  const _tableColumns = [
    {
      title: 'SKU 編號',
      dataIndex: 'sku_id',
      key: 'sku_id',
      searchable: true,
      width: 220,
      fixed: 'left',
      render: (_, record) => {
        return (
          <Button type="link" onClick={() => setSelectedProduct(record)}>
            {record.sku_id}
          </Button>
        );
      },
    },
    {
      title: 'SKU 名稱',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
    },
    {
      title: '品牌',
      dataIndex: 'brand',
      key: 'brand',
      searchable: true,
    },
    {
      title: '扣法',
      dataIndex: 'cost_calc_type',
      key: 'cost_calc_type',
      // editable: true,
      width: 260,
      render: (_, record) => {
        let _recordInDirty = dirtyMap[record.id] || {};
        return (
          <Input.Group style={{display: 'flex'}} compact>
            <EditableCell
              width={140}
              record={record}
              fieldType="number"
              field="cost_calc_type"
              setDirtyMap={setDirtyMap}
            />
            <Select
              style={{
                border:
                  _recordInDirty['buy_cost_unit'] &&
                  _recordInDirty['buy_cost_unit'] !== record['buy_cost_unit']
                    ? '1px solid red'
                    : 'none',
              }}
              value={_recordInDirty['buy_cost_unit'] || record['buy_cost_unit']}
              onChange={(v) =>
                setDirtyMap((dirty) => {
                  return {
                    ...dirty,
                    [record.id]: {
                      ...(dirty[record.id] || {}),
                      buy_cost_unit: v,
                    },
                  };
                })
              }
              defaultValue={'tax_included'}>
              {[
                {label: '含稅', value: 'tax_included'},
                {label: '未稅', value: 'tax_excluded'},
              ].map((opt, idx) => (
                <Select.Option key={idx} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </Input.Group>
        );
      },
    },
    {
      title: '最新採購成本',
      dataIndex: 'buy_cost',
      key: 'buy_cost',
      width: 180,
      // editable: true,
      render: (_, record) => {
        return (
          <EditableCell
            width={140}
            record={record}
            field="buy_cost"
            fieldType="number"
            setDirtyMap={setDirtyMap}
            // latestChangeMap={latestChangeMap}
          />
        );
      },
    },
    {
      title: '合約成本',
      dataIndex: 'cost',
      key: 'cost',
      width: 150,
      render: (_, record) => {
        let _recordInDirty = dirtyMap[record.id] || {};
        record = {...record, ..._recordInDirty};
        let _cost = getMainProductCost({
          buyCost: record['buy_cost'],
          buyCostUnit: record['buy_cost_unit'],
          costCalcType: record['cost_calc_type'],
        });
        return _cost;
      },
    },
    {
      title: '運費',
      dataIndex: 'shipping_cost',
      key: 'shipping_cost',
      width: 180,
      render: (_, record) => {
        return (
          <EditableCell
            width={140}
            record={record}
            fieldType="number"
            field="shipping_cost"
            setDirtyMap={setDirtyMap}
          />
        );
      },
    },
    {
      title: '創建時間',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => a.created - b.created,
      render: (_, record, idx) => {
        return moment(record.created).format('YYYY-MM-DD HH:mm');
      },
    },
  ];

  const {
    loading: fetchingRecords,
    records,
    tableColumns,
    onTableChange,
    antdQueryState,
    updateList,
  } = useAntdTable({
    tableColumns: _tableColumns,
    fetchRecords: fetchData,
    pageSize: PAGE_SIZE,
    outsideRangeFilters: rangeFilters,
  });

  const onCreateProductBtnClick = React.useCallback(() => {
    setSelectedProduct({id: null});
  }, []);

  const onBulkCreateProductBtnClick = React.useCallback(() => {
    setActiveBulkUploadMainModal(true);
  }, []);

  const modifyData = async function () {
    // bulk update data to main_product collection
    // TODO: calc cost again and save to data
    console.log(dirtyMap);
    try {
      const bulkWriteOperations = Object.keys(dirtyMap)
        .map((k) => ({id: k, ...dirtyMap[k]}))
        .map((p) => {
          let originRecord = records.find((r) => r.id === p.id);
          let record = {...originRecord, ...p};
          let _cost = getMainProductCost({
            buyCost: record['buy_cost'],
            buyCostUnit: record['buy_cost_unit'],
            costCalcType: record['cost_calc_type'],
          });

          p = {
            ...p,
            cost: _cost,
          };

          let pId = p.id;
          delete p._id;
          delete p.id;
          return {
            method: 'update_one',
            payload: {
              query: {id: pId},
              data: p,
            },
          };
        });

      actions.setLoading(true);
      await JStorageActions.bulkWriteDocuments(
        'main_product',
        bulkWriteOperations,
      );
      setDirtyMap({});
      await updateList();
    } catch (err) {
      console.log(err);
      message.error('無法更新主檔資訊');
    } finally {
      actions.setLoading(false);
    }
  };

  const onBulkModalConfirm = React.useCallback(() => {
    // updateList();
  });

  if (selectedProduct) {
    return (
      <Wrapper>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button onClick={() => setSelectedProduct(null)}>返回</Button>
        </div>

        <MainProductDetail
          product={selectedProduct}
          style={{marginTop: 20}}
          onConfirm={() => updateList(antdQueryState)}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageTitle>商品主檔</PageTitle>
      <Table
        scroll={{x: tableColumns.length * 180 + 200}}
        rowKey={(r) => r.id}
        loading={fetchingRecords}
        dataSource={records}
        columns={tableColumns}
        onChange={onTableChange}
        pagination={{
          ...antdQueryState.pagination,
          showSizeChanger: false,
        }}
      />

      <div style={{display: 'flex', alignItems: 'center', padding: '20px 0'}}>
        <Button
          size="large"
          onClick={() => onBulkCreateProductBtnClick()}
          style={{margin: 10}}>
          批次新增
        </Button>
        <Button
          size="large"
          onClick={() => onCreateProductBtnClick()}
          style={{margin: 10}}>
          新增商品
        </Button>
        <Button
          size="large"
          type="primary"
          style={{margin: 10}}
          disabled={Object.keys(dirtyMap).length === 0}
          onClick={modifyData}>
          修改
        </Button>
      </div>

      <Tools.UploadImageButton style={{marginBottom: 10}} />
      <Tools.UploadShopeeImageButton />
      <BulkUploadMainProductModal
        visible={!!activeBulkUploadMainModal}
        onConfirm={onBulkModalConfirm}
        updateList={updateList}
        onClose={() => setActiveBulkUploadMainModal(null)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default MainProductList;
