import React from 'react';
import {Button} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import Config from '../../../data.json';
import FileUpload from '../../Components/FileUpload';

const UserOutlet = getOutlet('user');

function UploadImageButton({style = {}}) {
  const [img, setImg] = React.useState('');
  return (
    <div style={{padding: 10, border: '1px solid #cecece', ...style}}>
      <div style={{marginBottom: 10, fontSize: 16}}>圖片上傳</div>
      <FileUpload onFinished={(img) => setImg(img)} />
      {img && <div style={{marginTop: 10}}>{img}</div>}
    </div>
  );
}

function UploadShopeeImageButton(props) {
  const fileInputRef = React.useRef();
  const [imgId, setImgId] = React.useState('');

  async function uploadShopeeImage(fileInputElem) {
    if (!fileInputElem.files || fileInputElem.files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('image', fileInputElem.files[0]);

    try {
      AppActions.setLoading(true);
      const resp = await fetch(
        `${Config.apiHost}/shopee/upload/image?token=${
          UserOutlet.getValue().token
        }`,
        {
          method: 'POST',
          body: formData,
        },
      );

      return (await resp.json()).response.image_info.image_id;
    } catch (ex) {
      console.warn(ex);
    } finally {
      AppActions.setLoading(false);
    }
  }

  return (
    <div style={{padding: 10, border: '1px solid #cecece'}}>
      <div style={{marginBottom: 10, fontSize: 16}}>蝦皮圖片上傳</div>
      <input type="file" ref={fileInputRef} />
      <Button
        style={{marginRight: 10}}
        onClick={async () => {
          setImgId('');
          const imgId = await uploadShopeeImage(fileInputRef.current);
          if (imgId) {
            setImgId(imgId);
          } else {
            alert('上傳失敗, 請重新嘗試');
          }
        }}>
        上傳
      </Button>

      {imgId || '---'}
    </div>
  );
}

export {UploadImageButton, UploadShopeeImageButton};
