import React from 'react';
import {Form, Input, Radio, Select, Space} from 'antd';
import {FIELD_TYPE} from './FIELD_TYPE';
import FileUpload from '../../Components/FileUpload';

export function FormField(props) {
  let {field, setFormValues, form, formValues} = props;

  let _rules = [{required: !!field.required, message: '此欄位為必填'}];
  if (field.min || field.max) {
    let _appendMinMax = {};
    if (field.min !== undefined) {
      _appendMinMax['min'] = field.min;
    }
    if (field.max !== undefined) {
      _appendMinMax['max'] = field.max;
    }

    _appendMinMax['message'] = `長度必須為${field.min}～${field.max}。`;
    _rules.push(_appendMinMax);
  }
  const generalFormItemProps = {
    label: field.label,
    required: !!field.required,
    rules: _rules,
    extra: field.note || '',
  };

  if ([FIELD_TYPE.INPUT_WITH_UNIT].indexOf(field.type) === -1) {
    generalFormItemProps.name = field.key; // avoid onFieldsChange fire two changedFields element for one field
  }

  if (field.type === FIELD_TYPE.SELECT) {
    return (
      <Form.Item {...generalFormItemProps}>
        <Select placeholder={field.placeholder || ''}>
          {(field.options || []).map((opt, idx) => (
            <Select.Option key={idx} value={opt.value}>
              {opt.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.RADIO) {
    return (
      <Form.Item {...generalFormItemProps}>
        <Radio.Group>
          {(field.options || []).map((opt, idx) => (
            <Radio key={idx} value={opt.value}>
              {opt.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.INPUT_WITH_UNIT) {
    return (
      <Form.Item {...generalFormItemProps}>
        <Input.Group compact>
          <Form.Item {...generalFormItemProps} name={[field.key]} noStyle>
            <Input
              style={{width: '50%'}}
              placeholder={field.placeholder || ''}
            />
          </Form.Item>
          <Form.Item name={[field.units.key]} noStyle>
            <Select
              placeholder={field.units.placeholder || ''}
              defaultValue={field.units.defaultValue || undefined}>
              {field.units.options.map((opt, idx) => (
                <Select.Option key={idx} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.GROUP_INPTUTS) {
    return (
      <Form.Item {...generalFormItemProps}>
        {field.inputs.map((inp, idx) => (
          <Form.Item
            required={generalFormItemProps.required}
            name={[inp.key]}
            key={idx}
            noStyle
            rules={[
              {
                validator: (_, value) => {
                  if (generalFormItemProps.required) {
                    return value !== undefined && value !== ''
                      ? Promise.resolve()
                      : Promise.reject(new Error('此欄位為必填'));
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <Input
              style={{
                display: 'inline-block',
                width: 'calc((100% - 10px)/2)',
                marginLeft: idx % 2 === 1 ? 10 : 0,
                marginBottom: 10,
              }}
              placeholder={inp.placeholder || ''}
              addonAfter={inp.postfix || ''}
            />
          </Form.Item>
        ))}
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.RADIO_WITH_INPUT) {
    return (
      <Form.Item {...generalFormItemProps}>
        <Radio.Group>
          <Space direction="vertical" style={{marginTop: 6}}>
            {(field.options || []).map((opt, idx) => (
              <Radio key={idx} value={opt.value}>
                {opt.label}
              </Radio>
            ))}

            {!!field.others && (
              <Input.Group compact>
                <Form.Item name={[field.others.input_key]} noStyle>
                  <Input
                    style={{width: 200}}
                    placeholder={field.others.input_placeholder || ''}
                  />
                </Form.Item>
                {!!field.others.units && (
                  <Form.Item name={[field.others.units.key]} noStyle>
                    <Select placeholder={field.others.units.placeholder || ''}>
                      {field.others.units.options.map((opt, idx) => (
                        <Select.Option key={idx} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Input.Group>
            )}
          </Space>
        </Radio.Group>
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.TEXTAREA) {
    return (
      <Form.Item {...generalFormItemProps}>
        <Input.TextArea
          showCount
          maxLength={field.max || 100}
          autoSize={{minRows: 4, maxRows: 4}}
        />
      </Form.Item>
    );
  } else if (field.type === FIELD_TYPE.UPLOAD) {
    return (
      <Form.Item {...generalFormItemProps} shouldUpdate>
        {!!formValues[field.key] && (
          <img
            src={formValues[field.key]}
            width={300}
            height={300}
            style={{objectFit: 'contain', marginRight: 10}}
          />
        )}
        <FileUpload onFinished={(url) => setFormValues({[field.key]: url})} />
      </Form.Item>
    );
  }
  return (
    <Form.Item {...generalFormItemProps}>
      <Input
        disabled={!!field.disabled}
        placeholder={field.placeholder || ''}
      />
    </Form.Item>
  );
}
