import {FIELD_TYPE} from './FIELD_TYPE';

const FIELDS = [
  {
    label: '上架狀態',
    key: 'published',
    type: FIELD_TYPE.RADIO,
    required: true,
    defaultValue: false,
    options: [
      {label: '上架', value: true},
      {label: '停用', value: false},
    ],
  },
  {
    label: 'SKU 編號',
    key: 'sku_id',
    placeholder: '請輸入',
    required: true,
  },
  {
    label: 'SKU 名稱',
    key: 'name',
    placeholder: '請輸入',
    required: true,
    min: 10,
    max: 60,
  },
  //   {
  //     label: 'SKU 分類',
  //     key: 'sku_category',
  //     placeholder: '請輸入',
  //     required: false,
  //   },
  {
    label: '平台分類（Yahoo 商城）',
    key: 'sku_category_yahoomall',
    placeholder: '請輸入',
    required: true,
  },
  {
    label: '平台分類（樂天）',
    key: 'sku_category_rakuten',
    placeholder: '請輸入', // 10910
    required: true,
  },
  {
    label: '平台分類（蝦皮）',
    key: 'sku_category_shopee', // 100159
    placeholder: '請輸入',
    required: true,
  },
  // {
  //   label: '平台分類（Momo 商城）',
  //   key: 'sku_category_momomall', // 2700400070
  //   placeholder: '請輸入',
  //   required: true,
  // },
  {
    label: '運費識別碼（Yahoo 商城）',
    key: 'shipping_id_yahoomall',
    required: true,
    defaultValue: 'normal',
    type: FIELD_TYPE.SELECT,
    options: [
      {label: '貨運 / 宅配', value: '2'},
      {label: '優惠專案', value: '5'},
      {label: '7-11取貨付款', value: '80'},
      {label: '全家取貨付款', value: '82'},
    ],
  },
  // {
  //   label: '商店類別碼（Momo 商城）',
  //   key: 'store_category_momomall', // 1101200002
  //   placeholder: '請輸入',
  //   required: true,
  // },
  {
    label: '配送溫層',
    key: 'delivery_temperature',
    type: FIELD_TYPE.RADIO,
    required: false,
    defaultValue: 'normal',
    options: [
      {label: '常溫', value: 'normal'},
      {label: '冷藏', value: 'fridge'},
      {label: '冷凍', value: 'freeze'},
    ],
  },
  {
    label: '配送方式',
    key: 'delivery_type',
    placeholder: '請輸入，例：Z1',
    required: true,
  },
  {
    label: '進項稅額註記',
    key: 'output_tax_note',
    type: FIELD_TYPE.RADIO,
    required: false,
    defaultValue: 'tax',
    options: [
      {label: '應稅', value: 'tax'},
      {label: '免稅', value: 'duty_free'},
      {label: '零税', value: 'zero_tax'},
    ],
  },
  {
    label: '扣法',
    key: 'cost_calc_type',
    placeholder: 'AA10BB2500',
    type: FIELD_TYPE.DEFAULT,
    note: 'AA請勿輸入%，若為 10% 請輸入 AA10 即可',
  },
  {
    label: '最新採購成本',
    key: 'buy_cost', // FIXME: to number
    placeholder: '0',
    required: true,
    type: FIELD_TYPE.INPUT_WITH_UNIT,
    units: {
      key: 'buy_cost_unit',
      placeholder: '稅制',
      defaultValue: 'tax_included',
      options: [
        {label: '含稅', value: 'tax_included'},
        {label: '未稅', value: 'tax_excluded'},
      ],
    },
  },
  {
    label: '運費',
    key: 'shipping_cost', // FIXME: to number
    placeholder: '0',
    required: true,
    type: FIELD_TYPE.INPUT_WITH_UNIT,
    units: {
      key: 'shipping_cost_unit',
      placeholder: '稅制',
      defaultValue: 'tax_included',
      options: [
        {label: '含稅', value: 'tax_included'},
        {label: '未稅', value: 'tax_excluded'},
      ],
    },
  },
  {
    label: '合約成本',
    key: 'cost',
    placeholder: '0',
    disabled: true, // FIXME: disabled, 另外算，不讓他上傳
    required: false,
    type: FIELD_TYPE.DEFAULT,
  },
  {
    label: '市價（含稅）',
    key: 'market_price',
    placeholder: '0', // FIXME: to number
    required: true,
    type: FIELD_TYPE.DEFAULT,
  },
  {
    label: '促銷成本',
    key: 'sale_cost',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: '促銷日期',
    key: 'sales_expired_date',
    placeholder: '請輸入 YYYY-MM-DD',
    required: false,
  },
  {
    label: '售價（含稅）',
    key: 'sale_price',
    placeholder: '0', // FIXME: to number
    required: true,
    type: FIELD_TYPE.DEFAULT,
  },
  {
    label: '規格註記',
    key: 'spec_note',
    type: FIELD_TYPE.RADIO,
    required: false,
    defaultValue: 'none',
    options: [
      {label: '無規格', value: 'none'},
      {label: '單規格', value: 'single'},
      {label: '雙規格', value: 'double'},
    ],
  },
  {
    label: '停產註記',
    key: 'out_of_production',
    required: false,
    type: FIELD_TYPE.RADIO_WITH_INPUT,
    options: [
      {label: '正常', value: 'ok'},
      {label: '停產', value: 'stop'},
    ],
    others: {
      input_key: 'out_of_production_date',
      label: '停產註記日期（YYYY-MM-DD）',
      input_placeholder: 'YYYY-MM-DD',
    },
  },
  {
    label: 'SKU 屬性',
    key: 'sku_type',
    type: FIELD_TYPE.RADIO,
    required: false,
    defaultValue: 'normal',
    options: [
      {label: '一般', value: 'normal'},
      {label: '即期品', value: 'spot'},
      {label: '廠商贈品', value: 'giveaway'},
    ],
  },
  {
    label: '供應商',
    key: 'supplier',
    placeholder: '請選擇供應商',
    required: false,
  },
  {
    label: '最低採購量',
    key: 'minimum_buy_count',
    placeholder: '請輸入',
    required: false,
    type: FIELD_TYPE.INPUT_WITH_UNIT,
    units: {
      key: 'minimum_buy_count_unit',
      placeholder: '單位',
      defaultValue: 'piece',
      options: [
        {label: '個', value: 'piece'},
        {label: '組', value: 'set'},
      ],
    },
  },
  {
    label: '安全庫存量',
    key: 'safe_stock_count',
    placeholder: '請輸入',
    required: false,
    type: FIELD_TYPE.INPUT_WITH_UNIT,
    units: {
      key: 'safe_stock_count_unit',
      placeholder: '單位',
      defaultValue: 'piece',
      options: [
        {label: '個', value: 'piece'},
        {label: '組', value: 'set'},
      ],
    },
  },
  {
    label: '保存天數',
    key: 'retention_period',
    type: FIELD_TYPE.RADIO_WITH_INPUT,
    options: [
      {label: '永久', value: 'perpetual'},
      {label: '天數', value: 'limited'},
    ],
    others: {
      input_key: 'retention_period_count',
      label: '保存天數數值',
      input_placeholder: '請輸入',
      units: {
        key: 'retention_period_unit',
        label: '保存天數數值單位',
        defaultValue: 'day',
        options: [
          {label: '日', value: 'day'},
          {label: '月', value: 'month'},
        ],
      },
    },
  },
  // 進階資料
  {
    label: '品牌',
    key: 'brand',
    placeholder: '請輸入',
    required: true,
  },
  {
    label: 'SKU 國際條碼',
    key: 'sku_international_code',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: '原廠料號',
    key: 'origin_part_id',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: 'SKU 型號',
    key: 'sku_no',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: '外箱尺寸／重量',
    key: null,
    type: FIELD_TYPE.GROUP_INPTUTS,
    inputs: [
      {
        label: '長',
        key: 'outside_box_l',
        postfix: '公分',
        placeholder: '長 0.0',
      },
      {
        label: '寬',
        key: 'outside_box_w',
        postfix: '公分',
        placeholder: '寬 0.0',
      },
      {
        label: '高',
        key: 'outside_box_h',
        postfix: '公分',
        placeholder: '高 0.0',
      },
      {
        label: '重',
        key: 'outside_box_weight',
        postfix: '公斤',
        placeholder: '重 0.0',
      },
    ],
  },
  {
    label: '備註',
    key: 'note',
    type: FIELD_TYPE.TEXTAREA,
    placeholder: '請輸入',
  },
  {
    label: '品牌公司名稱',
    key: 'brand_company_name',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: '外箱國際條碼',
    key: 'out_box_international_no',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: '原產地',
    key: 'origin_country',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: 'SKU 材質',
    key: 'sku_material',
    placeholder: '請輸入',
    required: false,
  },
  {
    label: 'SKU 尺寸／重量',
    key: null,
    type: FIELD_TYPE.GROUP_INPTUTS,
    required: true,
    inputs: [
      {label: '長', key: 'sku_box_l', postfix: '公分', placeholder: '長 0.0'},
      {label: '寬', key: 'sku_box_w', postfix: '公分', placeholder: '寬 0.0'},
      {label: '高', key: 'sku_box_h', postfix: '公分', placeholder: '高 0.0'},
      {
        label: '重',
        key: 'sku_box_weight',
        postfix: '公斤',
        placeholder: '重 0.0',
      },
    ],
  },
  {
    label: 'SKU 圖',
    key: 'sku_image',
    type: FIELD_TYPE.UPLOAD,
    required: true,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: 'SKU 圖 2',
    key: 'sku_image_2',
    type: FIELD_TYPE.UPLOAD,
    required: false,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: 'SKU 圖 3',
    key: 'sku_image_3',
    type: FIELD_TYPE.UPLOAD,
    required: false,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: '商品簡介',
    key: 'info',
    type: FIELD_TYPE.TEXTAREA,
    placeholder: '請輸入',
    required: true,
    min: 10,
    max: 50,
  },
  {
    label: '商品簡介（蝦皮）',
    key: 'info_shopee',
    type: FIELD_TYPE.TEXTAREA,
    placeholder: '請輸入',
    required: true,
    min: 50,
    max: 3000,
  },
  {
    label: '商品簡介（html）',
    key: 'info_html',
    type: FIELD_TYPE.TEXTAREA,
    placeholder: '請輸入 html 格式',
    required: true,
    min: 50,
    max: 3000,
  },
  {
    label: '平台圖 1（蝦皮）',
    key: 'sku_image_1_shopee',
    required: true,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: '平台圖 2（蝦皮）',
    key: 'sku_image_2_shopee',
    required: true,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: '平台圖 3（蝦皮）',
    key: 'sku_image_3_shopee',
    required: true,
    note: '圖片建議寬高 1000px x 1000px （比例為正方形）',
  },
  {
    label: '是否為商城商品',
    key: 'type_is_mall',
    type: FIELD_TYPE.RADIO,
    required: true,
    note: '此值會影響內部產品的顯示，若編輯此值，務必點選「同步內部產品」',
    options: [
      {label: '是', value: true},
      {label: '否', value: false},
    ],
  },
  {
    label: '是否為購物中心商品',
    key: `type_is_center`,
    type: FIELD_TYPE.RADIO,
    required: true,
    note: '此值會影響內部產品的顯示，若編輯此值，務必點選「同步內部產品」',
    options: [
      {label: '是', value: true},
      {label: '否', value: false},
    ],
  },
];

function getPlainFields() {
  let result = FIELDS.map((f) => {
    let topLevelGeneralProps = {
      defaultValue: f.defaultValue === undefined ? undefined : f.defaultValue,
      min: f.min === undefined ? undefined : f.min,
      max: f.max === undefined ? undefined : f.max,
      required: !!f.required,
      disabled: !!f.disabled,
      key: f.key,
      label: f.label,
    };

    // clean up undefined property
    for (let k in topLevelGeneralProps) {
      if (topLevelGeneralProps[k] === undefined) {
        delete topLevelGeneralProps[k];
      }
    }

    if (f.type === FIELD_TYPE.RADIO) {
      return {
        ...topLevelGeneralProps,
        options: f.options,
      };
    } else if (f.type === FIELD_TYPE.SELECT) {
      return {
        ...topLevelGeneralProps,
        options: f.options,
      };
    } else if (f.type === FIELD_TYPE.INPUT_WITH_UNIT) {
      let _unitsField = {
        key: f.units.key,
        label: f.units.placeholder || f.units.key,
        options: f.units.options,
        required: !!f.required,
        disabled: !!f.disabled,
        defaultValue: f.units.defaultValue || undefined,
      };
      if (_unitsField.defaultValue === undefined) {
        delete _unitsField.defaultValue;
      }
      return [
        {
          ...topLevelGeneralProps,
        },
        {
          ..._unitsField,
        },
      ];
    } else if (f.type === FIELD_TYPE.GROUP_INPTUTS) {
      return f.inputs.map((fi) => ({
        key: fi.key,
        label: `${f.label}-${fi.label}`,
        required: !!f.required,
        disabled: !!f.disabled,
      }));
    } else if (f.type === FIELD_TYPE.RADIO_WITH_INPUT) {
      let _radioFields = [
        {
          ...topLevelGeneralProps,
          options: f.options,
        },
      ];

      if (f.others) {
        _radioFields.push({
          key: f.others.input_key,
          label: f.others.label || f.others.input_key,
          required: false,
          disabled: !!f.disabled,
        });

        if (f.others.units) {
          let _radioOthersUnitsField = {
            key: f.others.units.key,
            label: f.others.units.label || f.others.units.key,
            required: !!f.required,
            options: f.others.units.options,
            disabled: !!f.disabled,
            defaultValue: f.others.defaultValue || undefined,
          };

          if (_radioOthersUnitsField.defaultValue === undefined) {
            delete _radioOthersUnitsField.defaultValue;
          }

          _radioFields.push(_radioOthersUnitsField);
        }
      }
      return _radioFields;
    }
    return {
      // TEXTAREA, DEFAULT, UPLOAD
      ...topLevelGeneralProps,
    };
  }).flat();
  return result;
}

export {FIELDS, getPlainFields};
